var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"text-center"},[_vm._v("APUNTES")]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.createAnnotation}},[_c('i',{staticClass:"fas fa-file"}),_vm._v(" Anotar ")])])]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 col-sm"},[_c('label',{attrs:{"for":""}},[_vm._v("Fecha")]),_c('app-datepicker',{on:{"change":_vm.afterChangeDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('div',{staticClass:"col-12 col-sm"},[_c('label',{attrs:{"for":""}},[_vm._v("Tipo")]),_c('SelectType',{on:{"change":function($event){return _vm.getContent()}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('div',{staticClass:"col-12 col-sm"},[_c('label',{attrs:{"for":""}},[_vm._v("Buscar")]),_c('app-input-search',{on:{"search":function($event){_vm.page = 1;
          _vm.date = null;
          _vm.getContent();}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.seeToday}},[_vm._v("ver apuntes de hoy")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.seeYesterday}},[_vm._v("Ver apuntes de ayer")])]),_c('li',[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.seeAll}},[_vm._v("Ver todo por fecha")])]),_c('li',[_c('span',[_vm._v("Total: "+_vm._s(_vm.total))])])])]),_c('div',{staticClass:"col-md-8"},[_vm._l((_vm.list),function(l){return _c('div',{key:l.id,staticClass:"card mb-2"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("dayOfWeek")(l.date))+" ")])]),_c('div',{staticClass:"col",attrs:{"title":_vm._f("dateTimeFormat")(l.created_at)}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(l.date))+" ")]),_c('div',{staticClass:"col text-end"},[_c('span',{staticClass:"badge bg-primary"},[_vm._v(" "+_vm._s(l.type == 1 ? "APUNTE" : "BORRADOR")+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(l.program_name),expression:"l.program_name"}],staticClass:"badge bg-success"},[_vm._v(" "+_vm._s(l.program_name)+" ")])])]),_c('div',{staticClass:"card-text"},[(l.text)?_c('NumberParagraph',{attrs:{"text":l.text},on:{"click-number":_vm.clickNumberHandler}}):_vm._e()],1),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn btn-primary me-1",on:{"click":function($event){_vm.$refs.elEditModal.show();
                _vm.$refs.elEditForm.setValue(l);}}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" Editar ")]),_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteItem(l.id)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Eliminar ")])])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('app-paginate',{ref:"elPagination",on:{"change":function($event){_vm.page = $event;
              _vm.getContent();}}})],1)])],2)]),_c('app-modal-basic',{ref:"elModalForm"},[_c('Form',{ref:"elForm",attrs:{"date":_vm.date,"see-program":true,"save-draft":true},on:{"submitted":function($event){_vm.getContent();
        _vm.$refs.elModalForm.hide();}}})],1),_c('app-modal-basic',{ref:"elEditModal"},[_c('Form',{ref:"elEditForm",attrs:{"see-date":true},on:{"submitted":function($event){_vm.getContent();
        _vm.$refs.elEditModal.hide();}}})],1),_c('app-modal-basic',{ref:"elNumModal"},[_c('CodeToCopy',{staticClass:"mb-2",attrs:{"text":_vm.number}}),_c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.search = _vm.number;
          _vm.type = null;
          _vm.date = null;
          _vm.getContent();
          _vm.$refs.elNumModal.hide();}}},[_c('i',{staticClass:"fas fa-search"}),_vm._v(" Buscar en apuntes ")])]),_c('PhoneButtons',{ref:"elButton",attrs:{"number":_vm.number}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }